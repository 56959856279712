import React, { useContext } from "react";
import { useQuery } from "react-query";
import converter from "number-to-words";
import { useLinkedBankAccountData } from "../../../../hooks/account.hooks";
import { usePropertyList } from "../../../../hooks/property.hooks";
import AccountListCarousel from "./account-list-carousel";
import ApplicationTitle from "./application-title";
import PropertyListCarousel from "./property-list-carousel";
import { FinanceContext } from "../../../../context/finance-context";
import { useMyInfo } from "../../../../hooks/user.hooks.ts";
import { getScenarioFinanceStatistic } from "../../../../api/finance-views";
import { JointScenarioConnectionStatus } from "../../../../types/join-account";

const FinanceStatistic = ({
  creditAndPersonalLoan = 0,
  propertyLoan = 0,
  properties = 0,
  assets = 0,
  firstName = "",
}) => {
  return (
    <>
      <div className="flex" style={{ gap: 8, marginBottom: 32 }}>
        <div className="font-bold">{firstName}’s Information</div>{" "}
        <div>(Information Only Visible to Broker)</div>
      </div>
      <ApplicationTitle
        title={`${converter.toWords(assets || 0)} Linked Bank Accounts `}
      />
      <ApplicationTitle
        title={`${converter.toWords(
          creditAndPersonalLoan || 0
        )} Credit Cards & Personal Loans `}
      />
      <ApplicationTitle
        title={`${converter.toWords(propertyLoan || 0)} Property Loans `}
      />
      <ApplicationTitle
        title={`${converter.toWords(properties || 0)} Property Assets `}
      />
    </>
  );
};

const LoanFinancials = () => {
  const linkedBankAccountData = useLinkedBankAccountData() || {};
  const { data: propertyList } = usePropertyList();
  const {
    assets = [],
    credit = [],
    personalLoan = [],
    propertyLoan = [],
  } = linkedBankAccountData;
  const creditAndPersonalLoan = [].concat(credit, personalLoan);
  const { data: userInfo } = useMyInfo();
  const { selectedScenarioDetail, selectedScenarioId } = useContext(
    FinanceContext
  );
  const { jointScenarioConnections } = selectedScenarioDetail;
  const { data: jointScenarioConnectionsFinance } = useQuery(
    ["getScenarioFinanceStatistic", selectedScenarioId],
    () => getScenarioFinanceStatistic(selectedScenarioId),
    { enabled: !!selectedScenarioId }
  );

  const mainUserFinanceData = (
    <>
      <div className="flex" style={{ gap: 8, marginBottom: 32 }}>
        <div className="font-bold">{userInfo?.firstName}’s Information</div>{" "}
      </div>
      <ApplicationTitle title="Linked Bank Accounts" />
      <AccountListCarousel accountList={assets} type="bankAccount" />
      <ApplicationTitle title="Credit Cards & Personal Loans" />
      <AccountListCarousel accountList={creditAndPersonalLoan} type="credit" />
      <ApplicationTitle title="Property Loans" />
      <PropertyListCarousel
        type="homeLoan"
        propertyList={propertyList}
        accountList={propertyLoan}
      />
      <ApplicationTitle title="Property Assets" />
      <PropertyListCarousel
        type="property"
        propertyList={propertyList}
        accountList={propertyLoan}
      />
    </>
  );

  return (
    <div>
      {mainUserFinanceData}
      {jointScenarioConnections?.map((connection) => {
        if (
          connection?.status === JointScenarioConnectionStatus.WAITING ||
          userInfo?.id === connection?.memberId
        ) {
          return <></>;
        }
        const { properties = 0, financeData = {} } =
          jointScenarioConnectionsFinance?.find(
            (j) => j?.memberId === connection?.memberId
          ) || {};

        return (
          <div key={connection?.id} style={{ marginTop: 32 }}>
            <FinanceStatistic
              firstName={connection?.member?.firstName}
              assets={financeData?.assets}
              creditAndPersonalLoan={
                financeData?.credit + financeData?.personalLoan
              }
              properties={properties}
            />
          </div>
        );
      })}
    </div>
  );
};

LoanFinancials.propTypes = {};

export default LoanFinancials;
